/*------------------------------------------------------------------
Theme Name: Motorboat
Version: 1.0
Author: Karl Haviland
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[TABLE OF CONTENTS]

        1. BLOCK STYLES
        2. HEADER

-------------------------------------------------------------------*/

/**
  BLOCK STYLES
**/
html, body {
    height: 100%;
    font-family: 'Raleway', sans-serif;
    background-color: #f0f3f6;
}

h2 {
    margin-bottom: 1rem;
    color: #333;
    font-family: bitter, serif;
}

/** TOP NAV **/
.thin-bar {
    border-bottom: 1px solid #333;
    padding-top: .4rem;
    height: 1.9rem;
    background: #71b48a;
    font-size: .9rem;
}
.thin-bar p {
    color: #fff;
}
.thin-bar a {
    color: #fff;
}
.thin-bar .fa {
    color: #fff;
}

/** NAV **/
.navbar {
    background-color: #FFD204;
    color: #fff;
    border-bottom: 1px solid #fff;
    padding: 0rem;
}

.navbar .container {
    padding: 1.5rem 0
}

.navbar-toggleable-md .navbar-brand{
    color: #fff;
    font-size: 2.5rem;
    font-family: bitter, serif;
}
.navbar-toggleable-md .navbar-brand  img{
    height: 3.5rem;
    padding-left: .7rem;
    padding-right: .3rem;
}

.navbar-toggleable-md .navbar-nav .nav-link {
    color: #fff;
    font-size: 1.4rem;
    padding: 0 .7rem 0 .7rem;
    font-family: 'Montserrat', sans-serif;
}

.nav-donate-link {
    font-weight: 600;
}

.navbar-toggleable-md .navbar-nav .nav-link:hover {
    color: #333;
    outline: none;
    background-color: transparent;
}

.navbar-toggleable-md .navbar-nav .nav-link:focus, .navbar-toggleable-md .navbar-nav .nav-link:active {
    color: #fff;
}

@font-face {
    font-family: bitter;
    src: url("../webfonts/Bitter-Regular.ttf");*/
}

/**
  HEADER
**/
.about-text {
    background: rgba(0,0,0,0.8);
    color: white;
    padding: 1.875rem;
}

.icon {
    padding-right: .3rem;
}

.header-social {
    padding-left: .1rem;
    text-align: right;
}

.header-social .social{
    display: inline-block;
    width: 2.6rem;
    height: 2.6rem;
    margin: 0 0 .1rem .2rem;
    padding-top: .2rem;
    background: #333;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-size: 1.2rem;
    color: #fff;
    line-height: 40px;
    text-align: center;
}

.header-social a:hover, .header-social a:focus, {
    background: #b5cabd;
    color: #fff;
}

.header-social a i {
    padding-bottom: .5rem;
    vertical-align: middle;
}

/***** TOP CONTENT *****/
#top-content {
    height: 55%;
    position: relative;
    background: url(../images/bg/smiles.jpg) center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#top-content .container {
    position: absolute;
    bottom: -4rem;
    position: absolute;
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%); /* This is a shorthand of
                                         translateX(-50%) and translateY(-50%) */
    text-align: center;
    width: 100%;
}

#lower-top-content .container {
    text-align: center;
}


.top-content .text {
    color: #fff;
}

.top-content .text h1 {
    font-weight: 300;
}
.top-content .text p {
    font-weight: 400;
}

.top-content .description {
    margin: 20px 0 10px 0;
}

.top-content .top-big-link {
    margin-top: 35px;
}

.medium-paragraph {
    font-size: 2.2rem;
    font-weight: 600;
    color: #fff;
    font-family: bitter, serif;
}

.btn-circle {
    font-size: 26px;
    width: 50px;
    height: 50px;
    margin-top: 15px;
    padding: 7px 0;
    -webkit-transition: background .3s ease-in-out;
    -moz-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
    color: #71b48a;
    border: 2px solid #71b48a;
    border-radius: 100% !important;
    background: transparent;
}

.btn-circle:hover, .btn-circle:focus {
    color: white;
    outline: none;
    background: rgba(255, 255, 255, 0.1);
}

#btncircle {
    border-radius: 50%;
    line-height: 38px;
    background-color: #333;
    bottom: 50px;
    text-align: center;
    width: 50px;
    right: 30px;
    transition: all 0.4s ease 0s;
    color: #FFF;
    font-size: 1.3rem;
    height: 50px;
    cursor: pointer;
}
#btncircle:hover {
    background-color: #b5cabd;
    transition: .4s;
    color: #fff;
}

/**
  SECTIONS
**/
.container {
    padding: 1.75rem 0;
}

section .container img {
    width: 100%;
    border-radius: 1.5rem;
    padding-bottom: 1rem;
}

.video-container {
    position:relative;
    padding-bottom:56.25%;
    padding-top:30px;
    height:0;
    overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
    position:absolute;
    top:0;
    left:0;
    width:100%
    height:100%;
}

/**
  FOOTER
**/
footer {
    border-top: 1px dotted #ddd;
    background: #FFD204;
    color: #f0f3f6;
    padding: 0rem;
}

footer .email-address {
    color: #333;
}

.footer-social {
    padding-left: .5rem;
    text-align: right;
}

.footer-social .social{
    display: inline-block;
    width: 2.6rem;
    height: 2.6rem;
    margin: 0 0 .1rem .2rem;
    padding-top: .2rem;
    background: #333;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-size: 1.2rem;
    color: #fff;
    line-height: 40px;
    text-align: center;
}

.footer-social a:hover, .footer-social a:focus {
    background: #b5cabd;
    color: #fff;
}

.footer-social a i {
    padding-bottom: .5rem;
    vertical-align: middle;
}

.footer-address {
    padding-right: .5rem;
}

/**
    BACK TO TOP
**/
#backtotop {
    border-radius: 50%;
    line-height: 48px;
    background-color: #333;
    display: block;
    position: fixed;
    bottom: 50px;
    text-align: center;
    width: 50px;
    right: 30px;
    opacity: 0;
    transition: all 0.4s ease 0s;
    color: #FFF;
    font-size: 11px;
    height: 50px;
    z-index: 99999;
    cursor: pointer;
}
#backtotop:hover {
    background-color: #b5cabd;
    transition: .4s;
    color: #fff;
}
#backtotop.active {
    opacity: 1;
}
