@media (max-width: 767px) {
    .navbar-toggleable-md .navbar-brand img {
        height: 2.8rem;
    }
    .navbar {
        text-align: center;
        padding: 0rem 0rem;
    }

    .navbar .container {
        padding: .75rem;
    }

    #top-content {
      height: 43%;
    }

    .container {
        padding: 1rem;
    }
    .top-content {
        height: 50%;
    }

    .navbar-toggleable-md .navbar-brand{
        font-size: 2rem;
    }

    .medium-paragraph {
        font-size: 1.35rem;
    }
}
